import * as React from 'react';
import { Typography } from 'antd';
import DefaultLayout from '../components/layouts/default.layout';
import { navigate } from 'gatsby';
import { Navigation } from '../components/navigation';
import UploadResumeZone from '../components/upload-resume';
import { IState } from 'state/types';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';

const { Title, Text, Paragraph } = Typography;

const IndexPage: React.FC = () => {
  const { country } = useSelector((state: IState) => state);
  const [countryState, setCountryState] = useState<string>('');

  useEffect(() => {
    setCountryState(country);
  }, [country]);

  return (
    <DefaultLayout>
      <div style={s.container}>
        <Title>Build your resume now</Title>

        <Paragraph>
          This site can help you create a beautiful resume to send to employers.
        </Paragraph>

        <Paragraph>Press Next to begin.</Paragraph>

        <Navigation
          step={0}
          goNextHandler={() =>
            navigate(`/work-experience${window.location.search}`)
          }
        />

        <div style={{ marginTop: 50 }}>
          <Text>or Upload a resume</Text>
          <UploadResumeZone />
        </div>
        <p style={s.link}>
          Go back to{' '}
          <a href={`https://workclass.co/${countryState}`}>WorkClass.co</a>
        </p>
      </div>
    </DefaultLayout>
  );
};

export default IndexPage;

const s: Stylesheet = {
  container: {
    textAlign: 'center',
    marginTop: 50
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20
  },
  img: {
    maxHeight: 35
  },
  link: {
    marginTop: 40
  }
};
